var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ul',{staticClass:"teeth_ul",class:'w65',on:{"click":function($event){$event.stopPropagation();return _vm.selectedTeeth($event)}}},[_c('li',{staticClass:"teeth_single"},[(['veneer'].includes(_vm.patientType))?_c('div',{staticClass:"single_02"},_vm._l(([11, 12, 13, 14, 15, 16, 17, 18]),function(item){return _c('p',{key:("teeth_" + item),class:{
          is_active: _vm.selectList.indexOf(item) !== -1
        },attrs:{"data-key":item}},[_c('span',{staticClass:"single_02_txt",attrs:{"data-key":item}},[_vm._v(_vm._s(item))]),_c('span',{staticClass:"tag_sp",attrs:{"data-key":item}})])}),0):_vm._e()]),_c('li',{staticClass:"teeth_single",class:{ children_height: _vm.patientType === 'children' }},[(['veneer'].includes(_vm.patientType))?_c('div',{staticClass:"single_02"},_vm._l(([21, 22, 23, 24, 25, 26, 27, 28]),function(item){return _c('p',{key:("teeth_" + item),class:{
          is_active: _vm.selectList.indexOf(item) !== -1
        },attrs:{"data-key":item}},[_c('span',{staticClass:"single_02_txt",attrs:{"data-key":item}},[_vm._v(_vm._s(item))]),_c('span',{staticClass:"tag_sp",attrs:{"data-key":item}})])}),0):_vm._e()]),_c('li',{staticClass:"teeth_single",class:{
      children_height: _vm.patientType === 'children',
      children_padding_top: _vm.patientType === 'children'
    }},[(['youth', 'adult', 'veneer'].includes(_vm.patientType))?_c('div',{staticClass:"single_02"},_vm._l(([41, 42, 43, 44, 45, 46, 47, 48]),function(item){return _c('p',{key:("teeth_" + item),class:{
          is_active: _vm.selectList.indexOf(item) !== -1
        },attrs:{"data-key":item}},[_c('span',{staticClass:"single_02_txt",attrs:{"data-key":item}},[_vm._v(_vm._s(item))]),_c('span',{staticClass:"tag_sp",attrs:{"data-key":item}})])}),0):_vm._e()]),_c('li',{staticClass:"teeth_single",class:{
      children_height: _vm.patientType === 'children',
      children_padding_top: _vm.patientType === 'children'
    }},[(['youth', 'adult', 'veneer'].includes(_vm.patientType))?_c('div',{staticClass:"single_02"},_vm._l(([31, 32, 33, 34, 35, 36, 37, 38]),function(item){return _c('p',{key:("teeth_" + item),class:{
          is_active: _vm.selectList.indexOf(item) !== -1
        },attrs:{"data-key":item}},[_c('span',{staticClass:"single_02_txt",attrs:{"data-key":item}},[_vm._v(_vm._s(item))]),_c('span',{staticClass:"tag_sp",attrs:{"data-key":item}})])}),0):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }